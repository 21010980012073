<template>
  <v-container fluid>
    <!-- {{ huecos }} -->
    <div class="d-flex mb-6 align-center" style="gap: 20px">
      <v-spacer />
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        class="mx-auto"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <h1 :class="`primary--text text-center mx-auto py-2 my-2 ${$vuetify.theme.dark ? 'pickerDark' : 'picker'}`" v-bind="attrs" v-on="on" style="width: 300px;font-size: xx-large;border-radius: 5px;">
            {{ date ? new Date(date).toLocaleDateString('esp',{ weekday :'short', day:'numeric',month:'short', year:'numeric' }).toLocaleUpperCase() : "CARGANDO..." }}
          </h1>
        </template>
        <v-date-picker
          v-model="dateAux"
          :first-day-of-week="1"
          no-title
          scrollable
          event-color="error"
          @click:date="menu = false;"
        ></v-date-picker>
      </v-menu>
      <v-spacer />
      <v-btn outlined icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents && isNotPb" :to="`/planificacion/${ date }`" >
        <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-notebook-multiple</v-icon>
      </v-btn>                
      <v-btn outlined icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents && isNotPb && isCat  && (!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['NECESIDADES']))" :to="`/necesidades/${ date }`" >
        <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-glass-wine</v-icon>
      </v-btn>
      <v-btn outlined icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents && isNotPb && isCat" :to="`/check_catering/${ date }`" >
        <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-truck</v-icon>
      </v-btn>
      <v-btn outlined :color="$vuetify.theme.dark ? '#53ADD4' : '#2491BF'" icon v-if="hasEvents && isNotPb && calc && (!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['RESUMEN_RENTABILIDAD']) || !!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['EVENTOS']))" :to="`/costes/${ date }`" >
        <v-icon small style="text-shadow: .6px .6px rgba(0, 0, 0, .7);">mdi-alarm-panel</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn @click.stop="savePlan" :loading="saving" color="primary">
        <v-icon left>mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </div>
    <div class="d-flex flex-wrap mb-6" style="gap: 20px">
      <HorasHueco
        @saved="getHuecos"
        v-for="(h, i) in huecos"
        :key="i"
        :hueco="h"
        :auxTi="100*i"
        v-model="huecos[i]"
        :date="dateParsed"
        @click="h.active = !h.active"
      />
    </div>
    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
export default {
  props: {
    date: String,
  },
  components: {
    HorasHueco: () => import("../components/HorasHueco.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      refreshKey: 0,
      ordenTipos: ["Boda", "Comunión", "Corporativo", "Evento", "Pruebas boda"],
      ordenEspacios: ["PISCIS", "MASMONZON", "LALANNE", "LAUS", "CATERING"],
      ordenSalones: ["ROSA", "ROYAL", "CONTINENTAL", "EL JARDIN", "LA CUCHARILLA", "MULTIUSOS", "PISTAS", "CLASH", "Blanco", "Antiguo", "LAGO", "LOS VIÑEDOS"],
      ordenHorarios: ["MAÑANA", "MAÑANA Y COMIDA", "TODO EL DÍA", "COMER", "COMIDA Y CENA", "TARDE", "TARDE Y CENA", "CENA", "PENDIENTE"],
      huecos: [],
      equipo: [],
      saving: false,
      menu: false,
      loading: false,
      filterCategories: {},
			autosave: null,
      dateAux: new Date(this.date + ' 12:00:00').toISOString().split('T')[0]
    };
  },
  watch:{
    dateAux:{
      async handler(v) {
        const confirmation = await this.$refs.confirm.open(
          "Guardar los cambios",
          "¿Quieres guardar los cambios antes de irte?",
          { color: "primary" }
        );
        if (confirmation) {
          await this.savePlan(true);
        }
        this.$set(this.$data, "dateAux", this.dateAux)
        this.$router.push({path : "/horas/" + this.dateAux}).catch(() => {})
      },
    }
  },
  computed: {
    async getEquipo() {
      this.loading = true;
      const { data } = await axios({
        url: "/unidades_equipo",
        params: {
          fecha: this.date,
        },
      });
      // this._equipo = data;
      this.$set(this.$data, "equipo", data);

      this.filterCategories = this.equipo
        .map((x) => ({ name: x.nombreCategoria, color: x.color, value : true }))
				.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i)
				.reduce( (t, c) => {
					t[ c.name ] = c
					return t
				}, {} )

      this.loading = false;
    },
    dateParsed() {
      return new Date(this.date).toLocaleDateString();
    },
    prevDate() {
      const date = new Date(this.date);
      date.setDate(date.getDate() - 1);
      return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
        -2
      )}-${("0" + date.getDate()).slice(-2)}`;
    },
    nextDate() {
      const date = new Date(this.date);
      date.setDate(date.getDate() + 1);
      return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
        -2
      )}-${("0" + date.getDate()).slice(-2)}`;
    },
  },
  methods: {
    async savePlan(skip){
      const confirmation =
        skip ||
        (await this.$refs.confirm.open(
          "Guardar los cambios",
          "¿Estás seguro de que quieres guardar los cambios?",
          { color: "primary" }
        ));
      if (!confirmation) return;

      const result = this.huecos.reduce((r, c) => {
        if (c.plan && c.plan.length > 0)
          r[c.custom_key] ??= c.plan.map(({ idUnidadEquipo, idCategoriaEquipo, entrada, salida, pHora, pHoraAux, kms, varios }) => ({
            idUnidadEquipo, idCategoriaEquipo, entrada, salida, pHora, pHoraAux, kms, varios
          }));
        return r;
      }, Object.create(Object.prototype));
      const merce = this.huecos.reduce((r, c) => {
        if (c.mercenarios && c.mercenarios.length > 0)
          r[c.custom_key] ??= c.mercenarios.map(({ entrada, salida, pHora, kms, varios,nombre }) => ({
            entrada, salida, pHora, kms, varios,nombre
          }));
        return r;
      }, Object.create(Object.prototype));
      try {
        this.saving = true;
        await Promise.all([
        axios({
            method: "post",
            url: `/horas/${this.date}`,
            data: result,
          }),
          axios({
            method: "post",
            url: `/horas/mercenarios/${this.date}`,
            data: merce,
          }),
        ]);
        this.saving = false;

        this.$root.$emit("snack", "Se han guardado los cambios correctamente");

      } catch (err) {
        this.saving = false;
        console.error(err);
        this.$root.$emit("snack", "No se han podido guardar los cambios");
      }
    },
    async getHuecos() {
      const { data } = await axios({
        url: `/horas/${this.date}`,
      });
      console.log(data);
      this.huecos = data;
      this.huecos.sort((a, b) => 
      {
        var keyA = this.ordenTipos.indexOf(a.tipo);
        var keyB = this.ordenTipos.indexOf(b.tipo);
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        keyA = this.ordenHorarios.indexOf(a.horario);
        keyB = this.ordenHorarios.indexOf(b.horario);
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;

        keyA = this.ordenEspacios.indexOf(a.lugar.split('-')[0].slice(0,-1));
        keyB = this.ordenEspacios.indexOf(b.lugar.split('-')[0].slice(0,-1));
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        keyA = this.ordenSalones.indexOf(a.lugar.split('-')[1].slice(1));
        keyB = this.ordenSalones.indexOf(b.lugar.split('-')[1].slice(1));
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        return 0;
      });
    },
    hasEvents() {
      return this.huecos.length > 0;
    },
    isNotPb() {
      return this.huecos.filter((ev) => ["Boda","Comunión","Evento","Corporativo"].includes(ev.tipo)).length > 0;
    },
    isCat() {
      return this.huecos.filter((ev) => ev.nombreEspacio == "CATERING" ).length > 0;
    },
    calc() {
      return this.huecos.filter((ev) => (ev.tipo == "Boda" || (((ev.nAdultos || 0) + (ev.nNinos || 0)) > 49))).length > 0;
    }
  },
  mounted() {
    this.getHuecos();
		// Autosave
		const self = this
		// this.autosave = setInterval( async function () {
		// 	await self.savePlan(true);
		// }, 1000 * 60 * 3 )

  },
  async beforeRouteLeave(to, from, next) {
    const confirmation = await this.$refs.confirm.open(
      "Guardar los cambios",
      "¿Quieres guardar los cambios antes de irte?",
      { color: "primary" }
    );
    if (confirmation) {
      await this.savePlan(true);
    }
    next();
  },
	beforeDestroy() {
		// clearInterval( this.autosave )
	}
};
</script>

<style>
</style>